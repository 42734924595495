import React, { useState, useEffect } from 'react';
import { getAllCategoryApi, getAllSubcategoriesApi, addSubCategoryApi, deleteSubCategoryApi, updateSubCategoryApi } from '../../../Apis/Api';
import toast from 'react-hot-toast';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; // Import icons

const AddSubCategory = () => {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  // For the Edit Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editSubCategory, setEditSubCategory] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategoryApi();
        setCategories(response.data?.categories || []);
      } catch (err) {
        toast.error('Error fetching categories');
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await getAllSubcategoriesApi();
        const fetchedSubcategories = response.data?.subCategories || [];
        setSubcategories(fetchedSubcategories);
        setFilteredSubcategories(fetchedSubcategories);
      } catch (err) {
        toast.error('Error fetching subcategories');
      }
    };

    fetchCategories();
    fetchSubcategories();
  }, []);

  const handleDelete = async (subcategoryId) => {
    try {
      const response = await deleteSubCategoryApi(subcategoryId);
      if (response.data.success) {
        toast.success(response.data.message);
        const updatedSubcategories = await getAllSubcategoriesApi();
        const subcategoriesList = updatedSubcategories.data?.subCategories || [];
        setSubcategories(subcategoriesList);
        setFilteredSubcategories(subcategoriesList);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Something went wrong');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addSubCategoryApi({ name, categoryId });
      if (response.data.success) {
        toast.success(response.data.message);
        setName('');
        setCategoryId('');
        const updatedSubcategories = await getAllSubcategoriesApi();
        const subcategoriesList = updatedSubcategories.data?.subCategories || [];
        setSubcategories(subcategoriesList);
        setFilteredSubcategories(subcategoriesList);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Something went wrong');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = subcategories.filter((subcategory) =>
      subcategory.name.toLowerCase().includes(query)
    );
    setFilteredSubcategories(filtered);
  };

  // Open the Edit Modal
  const openEditModal = (subcategory) => {
    setEditSubCategory(subcategory);
    setName(subcategory.name);
    setCategoryId(subcategory.category._id); // Assuming category is an object
    setIsEditModalOpen(true);
  };

  // Close the Edit Modal
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setName('');
    setCategoryId('');
  };

  // Handle Edit Submission
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateSubCategoryApi(editSubCategory._id, { name, categoryId });
      if (response.data.success) {
        toast.success(response.data.message);
        closeEditModal();
        const updatedSubcategories = await getAllSubcategoriesApi();
        const subcategoriesList = updatedSubcategories.data?.subCategories || [];
        setSubcategories(subcategoriesList);
        setFilteredSubcategories(subcategoriesList);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className="mx-auto p-5 mt-10">
      <h2 className="text-gray-600 text-3xl font-bold mb-4">Add Subcategory</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Subcategory Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium">Select Category</label>
          <select
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select a Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Add Subcategory
        </button>
      </form>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Subcategories List</h3>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search subcategories..."
          className="block w-[50%] p-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
        />

        <table className="w-full text-sm text-left text-gray-500 border mt-3">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-6 py-3">SN</th>
              <th className="px-6 py-3">Subcategory Name</th>
              <th className="px-6 py-3">Category</th>
              <th className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubcategories?.length > 0 ? (
              filteredSubcategories.map((subcategory, index) => {
                const categoryId = typeof subcategory.category === 'object' ? subcategory.category._id : subcategory.category;
                const categoryName = categories.find(
                  (category) => category._id === categoryId
                )?.name || 'Unknown';

                return (
                  <tr key={subcategory._id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4">{subcategory.name}</td>
                    <td className="px-6 py-4">{categoryName}</td>
                    <td className="px-6 py-4">
                      <button
                        className="font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => openEditModal(subcategory)}
                      >
                                <FaEdit size={20} />
                      </button>
                      <button
                        className="font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-[#AB3430]"
                        onClick={() => {
                          if (window.confirm("Are you sure you want to delete this item?")) {
                            handleDelete(subcategory._id);
                          }
                        }}
                      >
                        <FaTrashAlt size={20} />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center">No subcategories found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md w-[40%]">
            <h2 className="text-xl font-bold mb-4">Edit Subcategory</h2>
            <form onSubmit={handleEditSubmit}>
              <div className="mb-4">
                <label className="block mb-2 font-medium">Subcategory Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 font-medium">Select Category</label>
                <select
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                >
                  <option value="">Select a Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="text-gray-500 border border-gray-500 py-2 px-4 rounded"
                  onClick={closeEditModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSubCategory;
