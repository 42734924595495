import React from 'react';
import bg from "../../assets/images/bg.jpg";
import women from "../../assets/images/women2.jpg";
import insta from "../../assets/icons/in.png";
import whatsapp from "../../assets/icons/whatsapp.png";
import women2 from "../../assets/images/women3.jpg";
import women3 from "../../assets/images/women4.jpg";
import { FaBullseye, FaEye, FaCheckCircle } from 'react-icons/fa';

const AboutUs = () => {
    return (
        <>
            <div className=''>
                <div
                    className="fixed top-0 left-0 w-full h-full bg-cover bg-center"
                    style={{ backgroundImage: `url(${bg})` }}
                ></div>
                <div className='mx-auto relative'>
                    <div className='bg-[#E3DCD7]'>
                        <div className='container mx-auto'>
                            <div className='grid lg:grid-cols-3 grid-cols-1 pt-10 px-10 pb-10'>
                                <div className='col-span-2'>
                                    <div className='flex flex-col justify-between'>
                                        <h2 className='text-gray-400'>
                                            FOUNDED
                                            2018
                                        </h2>
                                        <div className='text-[40px] pt-10 text-gray-600 valky font-bold'>Stand out of the crowd with <br /> your own style</div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='flex gap-10'>
                                        <div>
                                            <h1 className='font-bold text-[#AB3430] pt-5'>95%</h1>
                                            <h1 className='font-bold text-[#AB3430] pt-5'>3+</h1>
                                            <h1 className='font-bold text-[#AB3430] pt-5'>3 Lakh</h1>
                                        </div>
                                        <div>
                                            <h5 className="pt-5 text-gray-600">Satisfaction<br />client</h5>
                                            <h5 className="pt-5 text-gray-600">Stores in <br />Countries</h5>
                                            <h5 className='pt-5 text-gray-600'>Orders <br />completed</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg-transparent h-[350px] container mx-auto'>
                        <div className='mx-10'>
                            <div className='pt-5 text-[60px] text-white valky'>
                                Pranu Collection
                            </div>
                            <p className='text-white italic'>Pranu Collection is a women’s ethnic wear brand that celebrates the spirit of empowerment and independence. Our Attires are crafted to inspire confidence, encouraging women to embrace their uniqueness and stand tall in every aspect of life.</p>
                        </div>
                    </div>

                    <div className='bg-white px-auto px-10 pt-10 pb-10'>
                        <div className='grid lg:grid-cols-2'>
                            <div>
                                <div className='relative flex flex-col items-center'>
                                    <div className='border border-gray-500 rounded-[300px] lg:w-[400px] w-[200px] md:w-[300px] overflow-hidden lg:p-[30px] p-[20px] flex items-center justify-center'>
                                        <img
                                            src={women}
                                            alt='Women in kurta'
                                            className='lg:h-[600px] h-[400px] object-cover rounded-[280px]'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col justify-center'>
                                <h1 className='text-[28px] font-bold text-gray-800'>Pranu Collection</h1>
                                <p className="pt-10 text-gray-600">Pranu Collection is a fashion brand specializing in elegant and stylish women's kurtas. The brand is dedicated to offering a diverse range of designs that combine traditional charm with modern aesthetics. Pranu Collection aims to provide high-quality, comfortable, and affordable kurtas suitable for everyday wear, formal occasions, and festive events. With a focus on craftsmanship, fabric quality, and unique designs, Pranu Collection strives to be a go-to choice for women seeking timeless ethnic wear.</p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-[#E3DCD7] px-10 py-14">
  <h1 className="text-center text-3xl font-extrabold valky text-gray-800 mb-12">Our Mission & Vision</h1>
  <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
    <div className="flex gap-6 items-start">
      <FaBullseye className="text-[#AB3430] text-5xl" />
      <div>
        <h2 className="text-xl font-bold text-gray-800 mb-2">Our Mission</h2>
        <p className="text-gray-600 leading-relaxed italic">
          We aim to redefine the traditional ethnic wear market by combining elegance with a message of strength and individuality. Our mission is to empower women through fashion, resonating with their values and aspirations.
        </p>
      </div>
    </div>
    <div className="flex gap-6 items-start">
      <FaEye className="text-[#AB3430] text-5xl" />
      <div>
        <h2 className="text-xl font-bold text-gray-800 mb-2">Our Vision</h2>
        <p className="text-gray-600 leading-relaxed italic">
          To become a global symbol of women’s independence, pride, and style by offering ethnic wear that represents strength, freedom, and the rich heritage of culture.
        </p>
      </div>
    </div>
  </div>
</div>
<div className="bg-white px-10 py-14">
  <h1 className="text-center text-3xl font-extrabold valky text-gray-800 mb-10">Visit Our Store</h1>
  <div className="flex flex-col lg:flex-row items-start lg:items-center gap-10">
    {/* Contact Information */}
    {/* Map */}
    <div className="lg:w-1/2 w-full">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.6747408388396!2d85.3085965!3d27.7053317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1923219ab8a1%3A0x4ef7d1cc5e2ccadc!2sPranu%20Collections!5e1!3m2!1sen!2snp!4v1735123212455!5m2!1sen!2snp"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="rounded-md shadow-lg"
      ></iframe>
    </div>
    <div className="lg:w-1/2 text-gray-700 space-y-6">
      <div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Contact Information</h2>
        <p className="leading-relaxed">
          Visit <strong>Pranu Collection</strong> at our store in Kathmandu, Nepal, located in the bustling area of NewRoad. Our store offers a curated collection of stylish women's kurtas designed to make you stand out. We welcome you to explore our exclusive collection in person!
        </p>
      </div>
      <div>
        <p className="mb-2">
          <strong>Address:</strong> Kathmandu, Nepal, NewRoad
        </p>
        <p className="mb-2">
          <strong>Phone:</strong> +977 984-8556062
        </p>
        {/* <p>
          <strong>Email:</strong>{" "}
          <a
            href="mailto:info@pranucollection.com"
            className="text-[#AB3430] hover:underline"
          >
            info@pranucollection.com
          </a>
        </p> */}
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Follow Us:</h3>
        <div className="flex gap-4">
          <a
            href="https://www.instagram.com/pranucollectionofficial/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={insta}
              alt="Instagram"
              className="h-8 grayscale hover:grayscale-0"
            />
          </a>
          <a
            href="https://wa.me/9848556062"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsapp}
              alt="WhatsApp"
              className="h-8 grayscale hover:grayscale-0"
            />
          </a>
        </div>
      </div>
    </div>

    
  </div>
</div>


                    <div className='flex justify-center bg-[#F2F2F2]'>
                        <div className='pt-10 pb-10'>
                            <div className='flex gap-5 items-center valky font-bold text-[24px] pb-10 text-gray-600 justify-center'>
                                <img src={insta} alt="Instagram" className='h-[30px] grayscale' />
                                official_pranu_collection
                            </div>
                            <div className='grid lg:grid-cols-4 grid-cols-2 gap-2'>
                                {[women, women2, women3].map((img, index) => (
                                    <div key={index} className='relative group h-[200px] w-[200px]'>
                                        <img
                                            src={img}
                                            alt={`women${index + 1}`}
                                            className='h-full w-full object-cover'
                                        />
                                        <a
                                            href="https://www.instagram.com/pranucollectionofficial/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className='absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300'
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="white"
                                                className="w-10 h-10"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M7.75 2h8.5A5.25 5.25 0 0121.5 7.25v8.5A5.25 5.25 0 0116.25 21H7.75A5.25 5.25 0 012.5 15.75v-8.5A5.25 5.25 0 017.75 2zM16.5 7.75a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='fixed bottom-0 right-0 p-5 hover:cursor-pointer hover:grayscale-90'>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/9848556062">
                    <img src={whatsapp} alt="WhatsApp" className='h-[60px]' />
                </a>
            </div>
        </>
    );
};

export default AboutUs;
