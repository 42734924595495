import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MyRating from './TotalRating';
import { baseURL } from '../../Apis/Api';

const Card = ({ product }) => {
  const [isFavorited, setIsFavorited] = useState(false);

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  return ( 
    <div className="relative border rounded-md p-4 lg:mx-2 mx-1 hover:border-red-200 hover:shadow-md transition duration-300 ease-in-out mb-2">
      {/* Ensure you're using the correct property for the product's unique ID */}
      <Link to={`/viewProduct/${product._id}`} key={product._id} className='lg:block md:block flex'>
        <img
          src={`${baseURL}/${product.images[0]}`}
          alt={product.name}
          className=" lg:w-full w-[40%] md:w-full rounded-lg lg:h-[330px] md:h-[300px] h-[200px] object-cover hover:scale-105 transition duration-300 ease-in-out"
          />
          <div className='w-[50%] lg:pl-0 pl-2'>

          <div className="text-[#767676] pt-5 font-bold whitespace-nowrap overflow-hidden text-ellipsis">{product.name}</div>
          <div className='hidden lg:block '>
                        <MyRating productId={product._id} />
                        </div>
        <div className="pt-2">
          <span className="text-lg text-red-600 font-bold">Rs {product.discountedPrice}</span>
          <br />
          <span className="text-sm text-gray-500 line-through mr-2">Rs {product.fakePrice}</span>
        </div>
          </div>

      </Link>
    </div>
  );
};

export default Card;
