import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import fb from '../../assets/icons/fb.png';
import insta from '../../assets/icons/in.png';
import whatapp from '../../assets/icons/wa.png';
import img1 from "../../assets/images/img1.jpg";
import img2 from "../../assets/images/img1.jpg";
import img3 from "../../assets/images/img1.jpg";
import img4 from "../../assets/images/img1.jpg";
import img5 from "../../assets/images/img1.jpg";
import img6 from "../../assets/images/img1.jpg";

const Footer = () => {
  return (
    <>
      <div className="bg-[#EA1E39] relative border-t border-gray-300 mx-auto pt-10">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mx-10">
            <div className="pt-5">
              <h2 className="text-xl text-white font-semibold">PRANU COLLECTION</h2>
              <div className="pt-2 text-white italic text-sm">Mero Pahiran Mero Pahichan</div>
              <div className="pt-10 text-white ">Kathmandu, Nepal, NewRoad</div>
              <div className="text-white pt-5">+977 984-8556062</div>
              <div className="flex gap-5 items-center pt-5">
                <a href="https://www.facebook.com/pranucollection" target="_blank" rel="noopener noreferrer">
                  <img src={fb} alt="facebook" className="h-[28px] grayscale-[100%] hover:grayscale-0" />
                </a>
                <a href="https://www.instagram.com/pranucollectionofficial/" target="_blank" rel="noopener noreferrer">
                  <img src={insta} alt="instagram" className="h-[26px] grayscale-[100%] hover:grayscale-0" />
                </a>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/9848556062" target="_blank" rel="noopener noreferrer">
                  <img src={whatapp} alt="whatsapp" className="h-[30px] grayscale-[100%] hover:grayscale-0" />
                </a>
              </div>
            </div>
            <div className="pt-5">
              <h2 className="text-xl text-white font-semibold">FAQS</h2>
              <div className="pt-10 text-white">
                <Link to="/faq" className="hover:text-white">Information</Link>
              </div>
              <div className="text-white pt-5">
                <Link to="/faq" className="hover:text-gray-700">Payment</Link>
              </div>
              <div className="text-white pt-5">
                <Link to="/termsandcondition" className="hover:text-gray-700">Terms & Conditions</Link>
              </div>
            </div>
            <div className="pt-5">
              <h2 className="text-xl text-white font-semibold">COMPANY</h2>
              <div className="text-white pt-10">
                <Link to="/about" className="hover:text-gray-700">About Us</Link>
              </div>
              <div className="text-white pt-5">
                <Link to="/about" className="hover:text-gray-700">Contact</Link>
              </div>
              {/* <div className="text-gray-500 pt-5">
                <Link to="/about" className="hover:text-gray-700">Careers</Link>
              </div> */}
            </div>
            <div className="pt-5">
              <h2 className="text-xl text-white font-semibold">INSTAGRAM</h2>
              <div className="text-white pt-10">
                Follow or tag us with #pranucollection, @pranucollection
              </div>
              <div className="pt-5 grid grid-cols-3 gap-2">
                {[img1, img2, img3, img4, img5, img6].map((img, index) => (
                  <div key={index} className="relative group">
                    <img src={img} alt={`img${index + 1}`} className="h-[100px] object-cover w-[100%]" />
                    <a
                      href="https://www.instagram.com/pranucollectionofficial/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="white" className="w-10 h-10">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7.75 2h8.5A5.25 5.25 0 0121.5 7.25v8.5A5.25 5.25 0 0116.25 21H7.75A5.25 5.25 0 012.5 15.75v-8.5A5.25 5.25 0 017.75 2zM16.5 7.75a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                        />
                      </svg>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="px-10 pt-10">
            <hr />
          </div>
          <div className="text-center text-white p-5">
            © 2022 Pranu Collection. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
