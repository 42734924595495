import React, { useEffect, useState } from 'react';
import { getAllProductApi, baseURL } from '../../../Apis/Api';
import TotalRating from "../../component/TotalRating";
import { Link, useNavigate } from 'react-router-dom';

const GetNewProduct = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getAllProductApi();
                setProducts(response.data.products);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setError("Failed to fetch products");
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const displayedProducts = products
        .filter(product => product.showProductinSite)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by creation date
        .slice(0, 12); // Display 12 most recent products


    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold valky text-gray-600">New Arrivals</h2>
                <button
                    onClick={() => navigate('/category')}
                    className="text-red-500 hover:underline"
                >
                    View All
                </button>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {displayedProducts.map(product => (
                    <Link to={`/viewProduct/${product._id}`} key={product._id}>
                        <div className='border border-solid border-gray-200 rounded-lg p-4 hover:border-red-200 hover:shadow-md transition duration-300 ease-in-out'>
                            <img
                                src={`${baseURL}/${product.images[0]}`}
                                alt={product.name}
                                className="w-full rounded-lg lg:h-[350px] md:h-[300px] h-[200px] object-cover hover:scale-105 transition duration-300 ease-in-out"
                            />
                            <div className="text-[#767676] pt-3 font-bold whitespace-nowrap overflow-hidden text-ellipsis">
                                {product.name}
                            </div>
                            <div className='hidden lg:block'>
                                <TotalRating
                                    productId={product._id}
                                    averageRating={product.averageRating}
                                />
                            </div>
                            <div className="lg:mt-2">
                                <span className="lg:text-lg text-red-600 font-bold">Rs. {product.discountedPrice}</span>
                                <br />
                                <span className="text-sm text-gray-500 line-through">Rs. {product.fakePrice}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default GetNewProduct;
