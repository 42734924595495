import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import {
  addCategoryApi,
  getAllCategoryApi,
  deleteCategoryApi,
  updateCategoryApi,
} from '../../../Apis/Api'; // Import the API functions
import TablePagination from '@mui/material/TablePagination';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; // Import icons

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState(''); // Input for new category
  const [categories, setCategories] = useState([]); // All categories
  const [loading, setLoading] = useState(true); // Loading state
  const [searchQuery, setSearchQuery] = useState(''); // Search input
  const [page, setPage] = useState(0); // Pagination: current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Pagination: rows per page
  const [editPopup, setEditPopup] = useState(false); // State for edit popup visibility
  const [editCategory, setEditCategory] = useState(null); // State for the category being edited
  const [editName, setEditName] = useState(''); // State for the edited category name

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategoryApi();
        setCategories(response.data.categories); // Assuming categories are in the response
        setLoading(false);
      } catch (error) {
        toast.error('Error fetching categories!');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Filtered categories based on search query
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle adding a new category
  const handleAddCategory = async (e) => {
    e.preventDefault();

    try {
      const response = await addCategoryApi({ name: categoryName });
      toast.success(response.data.message);
      setCategoryName(''); // Reset input after success

      // Fetch categories again after adding
      const updatedCategories = await getAllCategoryApi();
      setCategories(updatedCategories.data.categories);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error adding category');
    }
  };

  // Handle deleting a category
  const handleDeleteCategory = async (id) => {
    try {
      const response = await deleteCategoryApi(id);
      toast.success(response.data.message);
      // Fetch categories again after deleting
      const updatedCategories = await getAllCategoryApi();
      setCategories(updatedCategories.data.categories);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting category');
    }
  };

  // Handle opening the edit popup
  const handleEditPopup = (category) => {
    setEditCategory(category);
    setEditName(category.name);
    setEditPopup(true);
  };

  // Handle updating a category
  const handleUpdateCategory = async (e) => {
    e.preventDefault();

    try {
      const response = await updateCategoryApi(editCategory._id, { name: editName });
      toast.success(response.data.message);

      // Fetch categories again after updating
      const updatedCategories = await getAllCategoryApi();
      setCategories(updatedCategories.data.categories);

      // Close the popup
      setEditPopup(false);
      setEditCategory(null);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error updating category');
    }
  };

  // Pagination: handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Pagination: handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to the first when rows per page change
  };

  // If still loading, show a loading message
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="mx-auto p-5 mt-10">
        <div className="text-gray-600 font-bold text-3xl">Add Category</div>

        {/* Add Category Form */}
        <form onSubmit={handleAddCategory}>
          <div className="mb-4 pt-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="categoryName"
            >
              Category Name
            </label>
            <input
              type="text"
              name="categoryName"
              placeholder="Enter category name"
              id="categoryName"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Category
          </button>
        </form>

        {/* Categories Table */}
        <div className="py-5">
          <hr />
        </div>
        <div>
          <h2 className="text-xl font-bold">All Categories</h2>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-xl mt-3">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">SN</th>
                  <th scope="col" className="px-6 py-3">Category Name</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((category, index) => (
                    <tr
                      key={category._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">{index + 1 + page * rowsPerPage}</td>
                      <td className="px-6 py-4">{category.name}</td>
                      <td className="px-6 py-4">
                        <button
                          className="text-blue-500 hover:underline"
                          onClick={() => handleEditPopup(category)}
                        >
                                                   <FaEdit size={20} />

                        </button>
                        <button
                          className="text-red-500 hover:underline ml-4"
                          onClick={() => handleDeleteCategory(category._id)}
                        >
                                                    <FaTrashAlt size={20} />

                        </button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <TablePagination
              component="div"
              count={filteredCategories.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Rows per page"
            />
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {editPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Edit Category</h2>
            <form onSubmit={handleUpdateCategory}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Category Name
                </label>
                <input
                  type="text"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save Changes
              </button>
              <button
                type="button"
                className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setEditPopup(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCategory;
