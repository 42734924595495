import React, { useState } from 'react';
import ViewProduct from './ViewProduct';
import AddProducts from './AddProducts';
import ViewInventory from './ViewInventory';
import TotalProductsLeft from './TotalProductsLeft';

const AddProduct = () => {
  const [view, setView] = useState('add'); // Set default view to 'add'

  return (
    <div className="p-4">
      <div className="pt-10 mt-3">
        <div className="flex justify-start gap-4">
          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'add' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('add')}
          >
            Add Product
          </button>

          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'view' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('view')}
          >
            View Listed Products
          </button>

          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'inventory' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('inventory')}
          >
            View Inventory
          </button>
          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'viewcategorywithcount' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('viewcategorywithcount')}
          >
            Total Product Count based on Category
          </button>
        </div>

        {view === 'view' && <ViewProduct />}
        {view === 'add' && <AddProducts />}
        {view === 'inventory' && <ViewInventory />}
        {view === 'viewcategorywithcount' && <TotalProductsLeft />}

      </div>
    </div>
  );
};

export default AddProduct;
