import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getAllCheckouts } from "../../../Apis/Api";
import * as XLSX from "xlsx";

const GetCheckoutAdmin = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [filteredCheckouts, setFilteredCheckouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    fetchCheckouts();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      filterCheckoutsByDate(selectedDate);
    } else {
      setFilteredCheckouts(checkouts);
    }
  }, [checkouts, selectedDate]);

  const fetchCheckouts = async () => {
    try {
      const { data } = await getAllCheckouts();
      if (data.success) {
        setCheckouts(data.data);
        setFilteredCheckouts(data.data);
      } else {
        toast.error("Failed to fetch checkout records.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching checkout records.");
    } finally {
      setLoading(false);
    }
  };

  const filterCheckoutsByDate = (date) => {
    const filtered = checkouts.filter((checkout) => {
      const checkoutDate = new Date(checkout.createdAt);
      const selectedDate = new Date(date);

      return (
        checkoutDate.getFullYear() === selectedDate.getFullYear() &&
        checkoutDate.getMonth() === selectedDate.getMonth() &&
        checkoutDate.getDate() === selectedDate.getDate()
      );
    });

    setFilteredCheckouts(filtered);
  };

  const exportToExcel = () => {
    // Map filtered checkouts to structured data
    const checkoutData = filteredCheckouts.map((checkout, index) => {
      const productDetails = checkout.products
        .map((product) => {
          const discount =
            product.price === checkout.totalAmount
              ? "No discount applied"
              : `Npr. ${product.price - checkout.totalAmount} (Discount Applied)`;
  
          return [
            `Name: ${product.productId?.name || product.name}`,
            `Size: ${product.size}`,
            `Color: ${product.color}`,
            `Quantity: ${product.quantity}`,
            `Price per quantity: Npr. ${product.price}`,
            `Discounted Price: ${discount}`,
          ]
            .filter((line) => line)
            .join("\n"); // Each attribute on a new line
        })
        .join("\n\n"); // Separate multiple products by an empty line
  
      // Separate out payment methods
      const paymentMethods = {
        "Cash": checkout.paymentMethod === "cash" ? checkout.totalAmount : 0,
        "Esewa": checkout.paymentMethod === "esewa" ? checkout.totalAmount : 0,
        "Fone Pay": checkout.paymentMethod === "fonepay" ? checkout.totalAmount : 0,
        "Khalti": checkout.paymentMethod === "khalti" ? checkout.totalAmount : 0,
      };
  
      return {
        "S.N": index + 1,
        "Date & Time": `${new Date(checkout.createdAt).toLocaleDateString()} ${new Date(checkout.createdAt).toLocaleTimeString()}`,
        "Product Details": productDetails,
        "Payment Method": checkout.paymentMethod.charAt(0).toUpperCase() + checkout.paymentMethod.slice(1),
        "Quantity": checkout.products.reduce((total, product) => total + product.quantity, 0),
        "Customer Name": checkout.customerFullName || "N/A",
        "Customer Mobile Number": checkout.mobileNumber || "N/A",
        "Remarks": checkout.remarks || "N/A",
        "Cash": paymentMethods["Cash"],
        "Esewa": paymentMethods["Esewa"],
        "Fone Pay": paymentMethods["Fone Pay"],
        "Khalti": paymentMethods["Khalti"],
        "Total Amount": checkout.totalAmount || "N/A",
      };
    });
  
    // Generate worksheet
    const ws = XLSX.utils.json_to_sheet(checkoutData);
  
    // Calculate the range for the Total Amount column
    const totalAmountColumn = "M"; // Assuming Total Amount is in column L
    const totalRows = filteredCheckouts.length + 1; // Account for 1-based indexing
  
    // Add a footer row with the formula
    const footerRow = {
      "S.N": "",
      "Date & Time": "",
      "Product Details": "",
      "Payment Method": "",
      "Quantity": "",
      "Customer Name": "",
      "Customer Mobile Number": "",
      "Remarks": "",
      "Cash": { t: "s", f: `\"Grand Total in Cash: NPR \" & TEXT(SUM(I2:I${totalRows}),\"#,##0.00\")` },
      "Esewa": { t: "s", f: `\"Grand Total in Esewa: NPR \" & TEXT(SUM(J2:J${totalRows}),\"#,##0.00\")` },
      "Fone Pay": { t: "s", f: `\"Grand Total in Fone Pay: NPR \" & TEXT(SUM(K2:K${totalRows}),\"#,##0.00\")` },
      "Khalti": { t: "s", f: `\"Grand Total Khalti: NPR \" & TEXT(SUM(L2:L${totalRows}),\"#,##0.00\")` },
      "Total Amount": { t: "s", f: `\"Grand Total: NPR \" & TEXT(SUM(${totalAmountColumn}2:${totalAmountColumn}${totalRows}),\"#,##0.00\")` },
    };
  
    XLSX.utils.sheet_add_json(ws, [footerRow], { skipHeader: true, origin: -1 });
  
    // Adjust column widths for better readability
    const colWidths = Object.keys(checkoutData[0]).map((key) => {
      if (key === "Product Details") {
        return { wch: 70 }; // Wider column for detailed content
      }
      if (key === "Cash" || key === "Esewa" || key === "Fone Pay" || key === "Khalti") {
        return { wch: 20 }; // Larger width for payment methods
      }
      if (key === "Total Amount") {
        return { wch: 25 }; // Wider column for total amounts
      }
      const maxLength = checkoutData.reduce((max, item) => {
        const value = item[key] ? item[key].toString() : "";
        return Math.max(max, value.length);
      }, key.length);
  
      return { wch: maxLength + 5 }; // Add padding
    });
  
    // Set the widths for all columns
    ws["!cols"] = colWidths;
  
    // Add borders to the entire worksheet
    const borderStyle = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  
    Object.keys(ws).forEach((cellKey) => {
      const cell = ws[cellKey];
      if (cell && cell.v) {
        // Apply borders to each cell
        if (!cell.s) cell.s = {};
        cell.s.border = borderStyle;
        cell.s.alignment = {
          wrapText: true, // Enable text wrapping
          vertical: "top", // Align text to the top
          horizontal: "center", // Align text to the center horizontally
        };
      }
    });
  
    // Create workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Checkouts");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(wb, "Checkouts.xlsx");
  };
  
  



  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6">
        Self-Checkout Records
      </h2>

      <div className="mb-4">
        <label htmlFor="dateFilter" className="mr-2">
          Filter by Date:
        </label>
        <input
          type="date"
          id="dateFilter"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      {loading ? (
        <div className="text-center text-blue-500">Loading...</div>
      ) : filteredCheckouts.length === 0 ? (
        <div className="text-center text-gray-500">
          No checkout records found.
        </div>
      ) : (
        <>
          <button
            onClick={exportToExcel}
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Export to Excel
          </button>

          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">#</th>
                <th className="border border-gray-300 px-4 py-2">
                  Date & Time
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Product Details
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Payment Method
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Total Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCheckouts.map((checkout, index) => (
                <tr key={checkout._id} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {new Date(checkout.createdAt).toLocaleDateString()} <br />
                    {new Date(checkout.createdAt).toLocaleTimeString()}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {checkout.products.map((product, i) => (
                      <div key={i} className="mb-4">
                        <p>
                          <strong>Name:</strong>{" "}
                          {product.productId?.name || product.name}
                        </p>
                        <p>
                          <strong>Size:</strong> {product.size}
                        </p>
                        <p>
                          <strong>Color:</strong> {product.color}
                        </p>
                        <p>
                          <strong>Quantity:</strong> {product.quantity}
                        </p>
                        <p>
                          <strong>Price per quantity:</strong> Npr.{" "}
                          {product.price}
                        </p>
                        <p>
                          <strong>Discounted Price:</strong>{" "}
                          {product.price === checkout.totalAmount
                            ? "No discount applied"
                            : `Npr. ${checkout.totalAmount - product.price
                            } (Discount Applied)`}
                        </p>
                        {checkout.customerFullName && (
                          <p>
                            <strong>Customer Name:</strong>{" "}
                            {checkout.customerFullName}
                          </p>
                        )}
                        {checkout.mobileNumber && (
                          <p>
                            <strong>Customer Mobile Number:</strong>{" "}
                            {checkout.mobileNumber}
                          </p>
                        )}
                        {checkout.remarks && (
                          <p>
                            <strong>Remarks:</strong> {checkout.remarks}
                          </p>
                        )}
                      </div>
                    ))}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {checkout.paymentMethod.charAt(0).toUpperCase() +
                      checkout.paymentMethod.slice(1)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    Npr. {checkout.totalAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default GetCheckoutAdmin;
