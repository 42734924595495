import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { getProfileApi } from "./Apis/Api";
import Home from "./pages/Home/Home";
import Navbar from "./pages/component/Navbar";
import './app.css';
import AdminDashboard from "./pages/admin/AdminDashboard";
import Footer from "./pages/component/Footer";
import AboutUs from "./pages/Home/AboutUs";
import Register from "./pages/loginRegister/Register";
import Profile from "./pages/profile/profile";
import ViewProduct from "./pages/viewProduct/ViewProduct";
import AddCard from "./pages/addToCard/AddCard";
import AdminRoutes from "./PrivateRoutes/AdminRoutes";
import Gallery from "./pages/Home/Gallery";
import PageNotFound from "./pages/component/PageNotFound";
import { Toaster } from "react-hot-toast";
import Category from "./pages/Home/Category";
import FAQ from "./pages/Home/FAQ.jsx";
import SalesDashboard from "./pages/sales/SalesDashboard.jsx";
import SalesRoutes from "./PrivateRoutes/SalesRoutes.jsx";
import ScrollToTop from "./pages/component/ScrollToTop.jsx";
import TermsAndConditions from "./pages/termsandcondition.jsx";
import NotFoundPage from "./NotFoundPage.jsx";

function App() {
  // Step 1: Get token and user from localStorage directly
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const token = localStorage.getItem('token'); 
    const storedUser = localStorage.getItem('user');

    // If token exists and user is already stored, don't fetch profile again
    if (token && storedUser) {
      setIsLoggedIn(true);
      setLoading(false); // We already have the user info
      return; 
    }

    // If token exists but user is not in localStorage, fetch the profile
    const fetchProfile = async () => {
      try {
        if (token) {
          const profileResponse = await getProfileApi();
          const user = profileResponse.data.user;
          localStorage.setItem('user', JSON.stringify(user)); // Store user in localStorage
          setIsLoggedIn(true); // Mark user as logged in
        } else {
          setIsLoggedIn(false);
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setIsLoggedIn(false);
      }
      setLoading(false); // Loading is done after API call
    };

    fetchProfile();
  }, []);

  // Show loading indicator while login state is being confirmed
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Navbar/>
        <ScrollToTop />
        <Routes>
          <>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/viewProduct" element={<ViewProduct />} />
            <Route path="/viewProduct/:id" element={<ViewProduct />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/category" element={<Category />} />
            <Route path="/addCart" element={<AddCard />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/termsandcondition" element={<TermsAndConditions />} />
          </>

          {/* Protected Admin Route */}
          <Route element={<AdminRoutes />}>
            <Route path="/admindashboard" element={<AdminDashboard />} />
          </Route>
          <Route element={<SalesRoutes />}>
            <Route path="/salesdashboard" element={<SalesDashboard />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />

{/* <Route path="/" element={<NotFoundPage />} />
<Route path="*" element={<NotFoundPage />} /> */}

        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
