import React, { useEffect, useState } from "react";
import { getAllProductApi, baseURL } from "../../../Apis/Api";
import TotalRating from "../../component/TotalRating";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import flashSell from "../../../assets/icons/flash.png";

const GetFlashSellProduct = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getAllProductApi();
        setProducts(response.data.products);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch products");
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Filter products to display only those marked with `isFlashSale`
  const flashSaleProducts = products
    .filter((product) => product.isFlashSale && product.showProductinSite)
    .slice(0, 8);

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1500 },
        items: 5,
      },
     LargeDesktops: {
        breakpoint: { max:1500, min: 1024 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
  return (
    <div>
      {/* View All link */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold valky text-gray-600">
          Flash Sale Products
        </h2>
        <button
          onClick={() => navigate("/category")}
          className="text-red-500 hover:underline"
        >
          View All
        </button>
      </div>

      {/* Carousel */}
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {flashSaleProducts.map((product) => (
          <Link
            to={`/viewProduct/${product._id}`}
            key={product._id}
            className=""
            productId={product._id}
            userId={product.userId}
          >
            <div className="border border-solid border-gray-200 rounded-lg m-2 p-4 relative hover:border-red-200 hover:shadow-md transition duration-300 ease-in-out">
              {/* Add padding around the image */}
              <div>
                <img
                  src={`${baseURL}/${product.images[0]}`} // Ensure this URL is correct
                  alt={product.name}
                  className="w-full rounded-lg lg:h-[350px] md:h-[300px] h-[200px] object-cover hover:scale-105 transition duration-300 ease-in-out"
                />
              </div>
              <div className="text-[#767676] pt-5 font-bold whitespace-nowrap overflow-hidden text-ellipsis">{product.name}</div>
              <div className="hidden lg:block md:block">
              <TotalRating 
  productId={product._id} 
  averageRating={product.averageRating} 
/>
              </div>
              <div className="lg:mt-2">
                <span className="text-lg text-red-600 font-bold">
                  Rs. {product.discountedPrice}
                </span>
                <br />
                <span className="text-sm text-gray-500 line-through mr-2">
                  Rs. {product.fakePrice}
                </span>
              </div>
              {/* Flash Sell badge */}
              {product.isFlashSale && (
                <div className="absolute top-[0px] lg:right-[-10px] right-[0px]">
                  <img
                    src={flashSell}
                    alt="flash sell"
                    className="lg:h-[50px] h-[30px] drop-shadow-lg"
                  />
                </div>
              )}
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default GetFlashSellProduct ;
