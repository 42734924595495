import React, { useState, useEffect } from 'react';
import logo from "../../assets/logos/logo.png";
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { toast } from 'react-hot-toast';
import { baseURL, getAllProductApi, logoutUserApi } from '../../Apis/Api';
import { useSelector } from 'react-redux';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const location = useLocation();
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
    useEffect(() => {
      const checkLoginStatus = async () => {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error("Error checking login status:", error);
          setIsLoggedIn(false); // Fallback to false if there is an error
        }
      };
    
      // Set timeout to run checkLoginStatus after 1 second
      const timeoutId = setTimeout(() => {
        checkLoginStatus();
      }, 1000);
    
      // Cleanup timeout on component unmount
      return () => clearTimeout(timeoutId);
    }, []); // Empty array ensures this runs only once
    
    // Fetch products from API on component mount
    useEffect(() => {
      getAllProductApi()
        .then((response) => {
          const productData = response.data.products || []; // Adjust based on API response structure
          setProducts(productData);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          setProducts([]);
        });
    }, []);
  
    // Update filteredProducts whenever searchText changes
    useEffect(() => {
      if (searchText.trim() === "") {
        setFilteredProducts([]); // Don't show products when the input is empty
      } else {
        const filtered = products.filter((product) => {
          console.log("Product during filtering:", product);
          return (
            product.name.toLowerCase().includes(searchText.toLowerCase()) &&
            product.showProductinSite === true // Only include products that have showProductinSite set to true
          );
        });
        setFilteredProducts(filtered);
      }
    }, [searchText, products]);
    
  
    const handleIconClick = () => {
      setIsInputVisible(!isInputVisible); 
    };
  
    const handleRemoveClick = () => {
      setSearchText(""); // Clear the search text
      setIsInputVisible(false); // Optionally hide the input field
    };
  
    const handleProductClick = (id) => {
      console.log("Navigating to product ID:", id);
      if (!id) {
        console.error("Product ID is undefined.");
        return;
      }
      navigate(`/viewProduct/${id}`);
    };
    
    
    const { cart } = useSelector((state) => ({
        cart: state.cartReducer.cart,
    }));
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (location.pathname === '/admindashboard') {
        return null; // Do not render the navbar on admin dashboard
    }
    if (location.pathname === '/salesdashboard') {
        return null; // Do not render the navbar on admin dashboard
    }

    // Logout function

    const handleLogout = async () => {
        try {
          const response = await logoutUserApi();
          if (response.data.success) {
            toast.success(response.data.message);
            localStorage.removeItem('token');
            localStorage.removeItem('hasReloadedProfile');
            localStorage.removeItem('user'); // Remove token
            setIsLoggedIn(false); // Update state
            navigate('/');
          } else {
            toast.error('Failed to log out');
          }
        } catch (error) {
          console.error('Error logging out:', error);
          toast.error('Failed to log out');
        }
      };
    // Show Login Modal
    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    // Show Register Modal
    const handleRegisterClick = () => {
        setShowRegisterModal(true);
    };

    // Handle closing the modals
    const handleCloseModal = () => {
        setShowLoginModal(false);
        setShowRegisterModal(false);
    };

    // Handle successful login (to update the state globally in App.js)
    const handleLoginSuccess = () => {
        setIsLoggedIn(true); // Update isLoggedIn state in App.js
        setShowLoginModal(false); // Close the modal after successful login
    };

    const handleProfileClick = () => {
      const hasReloaded = localStorage.getItem('hasReloadedProfile');
    
      if (!hasReloaded) {
        // Set a flag in localStorage to mark that the reload has already occurred
        localStorage.setItem('hasReloadedProfile', 'true');
        window.location.href = '/profile'; // Force reload
      } else {
        // Navigate without reloading
        navigate('/profile'); // If you use React Router's navigate function
      }
    };
    

    return (
        <div className={`sticky top-0 z-50 transition-shadow duration-300 bg-[#E3DCD7] ${isScrolled ? 'shadow-lg bg-white' : ''}`}>
            <div className='container mx-auto'>
                <div className='flex justify-between p-5 items-center'>
                    <NavLink to="/" >
                        <img src={logo} alt='logo' className='h-[50px]' />
                    </NavLink>
                    <div className='hidden md:flex gap-5'>
                        <NavLink to="/" exact className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Home</NavLink>
                        <NavLink to="/category" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Category</NavLink>
                        <NavLink to="/gallery" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Gallery</NavLink>
                        <NavLink to="/about" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>About</NavLink>
                    </div>
                    <div className='flex gap-5 items-center'>
                    <div className="relative">
      {/* Search Icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6 text-gray-500 cursor-pointer hover:text-red-500"
        onClick={handleIconClick}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>

      {/* Animated Input Field */}
      {isInputVisible && (
        <div className="absolute top-[-15px] right-0 flex flex-col bg-white text-gray-700 rounded shadow-md px-4 py-2 z-50">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="lg:w-64 md:w-48 w-40 p-[] text-sm border border-gray-300 rounded focus:outline-none py-1"
            />
            {/* Remove Button */}
            <button
              className="ml-2 px-1 py-1 text-sm text-gray-500 rounded hover:text-red-600"
              onClick={handleRemoveClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {/* Product List */}
          {searchText.trim() && (
            <div className="mt-4 max-h-64 overflow-y-auto">
              {filteredProducts.length > 0 ? (
             filteredProducts.map((product) => {
              console.log("Product ID:", product.id);
              return (
                <div
                  key={product.id}
                  className="flex items-center gap-4 p-2 border-b cursor-pointer hover:bg-gray-100"
                  onClick={() => handleProductClick(product._id)} // Use _id for navigation
                  >
                  <img
                            src={`${baseURL}/${product.images[0]}`} // Ensure this URL is correct
                            alt={product.name}
                    className="h-12 w-10 object-cover rounded"
                  />
                  <span className="font-medium text-sm">{product.name} </span>
                </div>
              );
            })
            
              ) : (
                <div className="text-sm text-gray-500">No products found.</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>

    {isLoggedIn ? (
                            <div className='hidden md:block'>


                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <MenuButton className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 hover:text-[#ab3430] text-gray-500" >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                            </svg>
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                <Link to="/profile"
                                                    onClick={handleProfileClick}
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                >
                                                    Account settings
                                                </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/faq"

                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                >
                                                    Support
                                                </Link>
                                            </MenuItem>

                                            <hr />
                                            <form >
                                                <MenuItem>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            onClick={handleLogout}
                                                            className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-left text-sm text-gray-700`}
                                                        >
                                                            Logout
                                                        </button>
                                                    )}
                                                </MenuItem>
                                            </form>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            </div>

                        ) : (
                            <>
                                <button className="hidden md:block text-gray-700 hover:text-[#AB3430]" onClick={handleLoginClick}>Login</button>
                                <button className="hidden md:block text-gray-700 hover:text-[#AB3430]" onClick={handleRegisterClick}>Register</button>
                            </>
                        )}

                        {/* Cart Icon */}
                        <Link to="/addCart" className='relative' >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 hover:text-[#ab3430] text-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                            </svg>
                            <span className="absolute right-[-15px] md:right-[-15px] top-[-10px] lg:right-[-15px] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-[#AB3430] rounded-full">
                                {cart.length}
                            </span>
                        </Link>

                        <button className='md:hidden' onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className={`md:hidden fixed top-0 right-0 w-64 h-full bg-[#E3DCD7] shadow-lg p-4 transition-transform transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300 z-50`}>
                <button className='absolute top-4 right-4' onClick={() => setIsMenuOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6m0 12L6 6" />
                    </svg>
                </button>
                <nav>
                    <ul className="space-y-4">
                        <li><NavLink to="/" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Home</NavLink></li>
                        <li><NavLink to="/category" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Category</NavLink></li>
                        <li><NavLink to="/gallery" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Gallery</NavLink></li>
                        <li><NavLink to="/about" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>About</NavLink></li>
                        <li>
                            {isLoggedIn ? (
                                <>
                          
                                <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { handleProfileClick(); setIsMenuOpen(false); }}>Profile</button>
                              <br/>
                                <button
                                        type="button"
                                        onClick={handleLogout}
                                    
                                    >
                                        Logout
                                    </button>
                                    </>
                              
                            ) : (
                                <>
                                    <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { setShowLoginModal(true); setIsMenuOpen(false); }}>Login</button>
                                    <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { setShowRegisterModal(true); setIsMenuOpen(false); }}> / Register</button>
                                </>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Modals */}
            {/* Login Modal */}
            <LoginModal
                isVisible={showLoginModal}
                onClose={handleCloseModal}
                onLoginSuccess={handleLoginSuccess}
            />

            {/* Register Modal */}
            <RegisterModal
                isVisible={showRegisterModal}
                onClose={handleCloseModal}
            />
        </div>
    );
};

export default Navbar;
