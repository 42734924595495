import React, { useState, useEffect } from 'react';
import { getAllProductApi, getAllCategoryApi, getAllSubcategoriesApi } from '../../../Apis/Api'; // Adjust the import paths as needed

const TotalProductsLeft = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const productResponse = await getAllProductApi();
        const categoryResponse = await getAllCategoryApi();
        const subCategoryResponse = await getAllSubcategoriesApi();

        if (productResponse.status === 200) {
          setProducts(productResponse.data.products || []);
        } else {
          setError('Failed to fetch products');
        }

        if (categoryResponse.status === 200) {
          setCategories(categoryResponse.data.categories || []);
        } else {
          setError('Failed to fetch categories');
        }

        if (subCategoryResponse.status === 200) {
          setSubCategories(subCategoryResponse.data.subCategories || []);
        } else {
          setError('Failed to fetch subcategories');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const categoryMap = categories.reduce((map, category) => {
    map[category._id] = category.name;
    return map;
  }, {});

  const subCategoryMap = subCategories.reduce((map, subCategory) => {
    map[subCategory._id] = subCategory.name;
    return map;
  }, {});

  // Group products by category and subcategory
  const groupedProducts = products.reduce((acc, product) => {
    const categoryName = categoryMap[product.category] || 'Unknown Category';
    const subCategoryName = subCategoryMap[product.subcategory] || 'Unknown Subcategory';

    if (!acc[categoryName]) {
      acc[categoryName] = { count: 0, subCategories: {} };
    }

    acc[categoryName].count += product.totalQuantity || 0;

    if (!acc[categoryName].subCategories[subCategoryName]) {
      acc[categoryName].subCategories[subCategoryName] = 0;
    }

    acc[categoryName].subCategories[subCategoryName] += product.totalQuantity || 0;
    return acc;
  }, {});

  let serialNumber = 1;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6 text-center">Total Products Left</h1>
      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-4 py-2">SN</th>
            <th className="border border-gray-300 px-4 py-2">Category Name</th>
            <th className="border border-gray-300 px-4 py-2">Subcategories & Items Left</th>
            <th className="border border-gray-300 px-4 py-2">Total Quantity</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedProducts).map((category) => (
            <tr key={category}>
              <td className="border border-gray-300 px-4 py-2 text-center">
                {serialNumber++}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {category}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {Object.entries(groupedProducts[category].subCategories).map(
                  ([subCategory, count]) => (
                    <div key={subCategory}>
                      {subCategory}: {count} items
                    </div>
                  )
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                {groupedProducts[category].count}
              </td>
            </tr>
          ))}
          <tr className="font-bold bg-gray-100">
            <td colSpan="3" className="border border-gray-300 px-4 py-2 text-right">
              Grand Total
            </td>
            <td className="border border-gray-300 px-4 py-2 text-center">
              {Object.values(groupedProducts).reduce(
                (total, category) => total + category.count,
                0
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalProductsLeft;
